import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  & path {
    fill: ${props => (props.fill ? props.fill : `#0E3563`)};
  }
`;

export const Close = ({ fill }) => (
  <Icon
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
  >
    <rect x="21.9204" width="5" height="31" transform="rotate(45 21.9204 0)" />
    <rect y="3.53552" width="5" height="31" transform="rotate(-45 0 3.53552)" />
  </Icon>
);

export const Next = ({ fill }) => (
  <Icon
    width="29"
    height="33"
    viewBox="0 0 29 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
  >
    <path d="M0 0L29 16.1269L0 32.2693L4.59677 16.1269L0 0Z" />
  </Icon>
);

export const Prev = ({ fill }) => (
  <Icon
    width="29"
    height="33"
    viewBox="0 0 29 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
  >
    <path d="M29 32.2693L4.9749e-07 16.1424L29 3.05176e-05L24.4032 16.1424L29 32.2693Z" />
  </Icon>
);
